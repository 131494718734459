<template>
  <v-app>
    <v-content>
      <Header></Header>
      <v-container fluid fill-height class="BackgroundStyle-2">
        <v-layout align-center justify-center>
          <v-flex xs12 sm4 md5 lg4>
            <div class="Logo1" width="100%">
              <img src="../../assets/Mww128.png" alt="My Wallet Watcher" align-center>
            </div>
            <v-card class="elevation-3">
              <v-card-text>
                <v-card-title class="primary-title text-xs-center">
                  <div>
                    <h3 class="headline"> Forgot your password? </h3> <br />
                    <span class="grey--text"> We've got your back, enter your email address below and we will send you some instructions. </span>
                  </div>
                </v-card-title>

                <v-alert :type="messageType" :value="showMessage" dismissible>
                  {{message}}
                </v-alert>
                <v-form @submit="valCheck($v.user)">
                  <div>
                    <v-text-field prepend-icon="mail"
                                  label="E-mail"
                                  v-model="email"
                                  :error-messages="emailErrors"
                                  @input="$v.email.$touch()"
                                  @blur="$v.email.$touch()">
                    </v-text-field>
                  </div>
                  <div>
                  </div>
                  <v-btn type="submit" color="primary" :loading="loading" rounded block v-on:click.prevent="valCheck($v.email)">
                    Reset Password
                  </v-btn>
                </v-form>
              </v-card-text>
            </v-card>
            <v-card-text class="text-xs-center">
              <v-divider light> </v-divider>
              <div>
                <br />
                have an account already? <a href="./Login" class="my-link"> Sign in </a>
              </div>
            </v-card-text>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>


<script>

  import { validationMixin } from 'vuelidate'
  import { required, email } from 'vuelidate/lib/validators'

  import { HTTP } from '../../api/httpCommon';

  import header from '../../components/header.vue'

  export default {
    mixins: [validationMixin],
    components: {
      'Header': header
    },
    data() {
      return {
        email: '',
        message: 'test',
        messageType: 'success',
        showMessage: false,
        loading: false
      }
    },
    computed: {
      emailErrors() {
        const errors = []
        if (!this.$v.email.$dirty) return errors
        !this.$v.email.email && errors.push('Must be valid e-mail')
        !this.$v.email.required && errors.push('E-mail is required')
        return errors
      }
    },
    methods: {
      valCheck: function ($v) {
        $v.$touch(); //Triggers Error Messages

        if ($v.$invalid) {
          return
        }

        this.loading = true

        this.passwordRecovery()
      },
      passwordRecovery: function () {
        this.showDismissibleAlert = true,

          HTTP.post('passwordRecovery',
            this.email
          )
            .then(response => {
              if (response.status === 200) {
                this.message = "Email Sent"
                this.messageType = 'success'
                this.showMessage = true   
              }
             else if (response.status === 404) {
                this.message = "Email Not Found"
                this.messageType = 'error'
                this.showMessage = true
              }
              else {
                this.message = "Something Went Wrong Please try again"
                this.messageType = 'error'
                this.showMessage = true
              }
              this.loading = false
            })
            .catch(e => {

              if (e.response.status === 404) {
                this.message = "Email Not Found"
                this.messageType = 'error'
                this.showMessage = true
              }
              else {
                this.message = "Something Went Wrong Please try again"
                this.messageType = 'error'
                this.showMessage = true
              }

              console.log(e)
              this.loading = false
            })

      }
    },
    validations: {
      email: {
        required,
        email
      }
    }
  }

</script>
