<template>
  <v-app>
    <v-content>
      <Header></Header>
      <v-container fluid fill-height class="BackgroundStyle-2">
        <v-layout align-center justify-center>
          <v-flex xs12 sm6 md5 lg4>
            <div class="Logo1" width="100%">
              <img src="../../assets/Mww128.png" alt="My Wallet Watcher" align-center>
            </div>
            <v-card class="elevation-3">
              <v-card-text>
                <v-card-title class="primary-title text-xs-center">
                  <div>
                    <h3 class="headline"> Set new password </h3>
                  </div>
                </v-card-title>
                <v-divider></v-divider>
                <v-form @submit="valCheck($v.passwords)">
                  <v-alert type="error" dismissible :value="true" v-model="showErrorMessage">
                    {{errorMessage}}
                  </v-alert>
                  <v-text-field v-model="passwords.password"
                                label="Password"
                                :append-icon="passwordShow ? 'visibility_off' : 'visibility'"
                                :type="passwordShow ? 'text' : 'password'"
                                :rules="[rules.strength]"
                                :error-messages="passwordErrors"
                                @input="$v.passwords.password.$touch()"
                                @blur="$v.passwords.password.$touch()"
                                @click:append="passwordShow = !passwordShow"></v-text-field>

                  <v-text-field v-model="passwords.cpassword"
                                label="Confirm Password"
                                :append-icon="cpasswordShow ? 'visibility_off' : 'visibility'"
                                :type="cpasswordShow ? 'text' : 'password'"
                                :error-messages="cPasswordErrors"
                                @input="$v.passwords.cpassword.$touch()"
                                @blur="$v.passwords.cpassword.$touch()"
                                @click:append="cpasswordShow = !cpasswordShow"></v-text-field>

                  <v-btn type="submit" color="primary" :loading="loading" rounded block v-on:click.prevent="valCheck($v.passwords)">
                    Change Password
                  </v-btn>

                </v-form>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>

    </v-content>
  </v-app>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required, sameAs } from 'vuelidate/lib/validators'

  import { passwordFunctions } from '../../javascript/passwordChecker'
  import { HTTP } from '../../api/httpCommon';

  import header from '../../components/header.vue'

  export default {
    mixins: [validationMixin],
    components: {
      'Header': header
    },
    data(vm) {
      return {
        passwordShow: false,
        cpasswordShow: false,
        loading: false,
        showErrorMessage: false,
        errorMessage: '',
        passwords: {
          password: '',
          cpassword: ''
        },
        rules: {
          strength: vm.getPasswordStrength
        },
      }
    },
    computed:{
      passwordErrors() {
        const errors = []
        if (!this.$v.passwords.password.$dirty) return errors
        !this.$v.passwords.password.required && errors.push('Password is required')
        return errors
      },
      cPasswordErrors() {
        const errors = []
        if (!this.$v.passwords.cpassword.$dirty) return errors
        !this.$v.passwords.cpassword.sameAsPassword && errors.push(' Passwords must be identical')
        !this.$v.passwords.cpassword.required && errors.push('Password is required')
        return errors
      },
    },
    methods: {
      valCheck: function ($v) {
        $v.$touch(); //Triggers Error Messages

        if ($v.password.$invalid || $v.cpassword.$invalid) {
          return
        }

        this.loading = true
        this.changePassword()
      },
      getPasswordStrength: function () {
        return passwordFunctions.isPasswordStrength(this.passwords.password)
      },
      changePassword: function () {
        this.showDismissibleAlert = true,

          HTTP.post('ChangePassword',
            this.passwords
          )
            .then(response => {
              if (response.status === 200) {
                this.$router.push({ path: '/dashboard/passwordChanged/Success' })
              }
              else if (response.status === 401) {
                this.errorMessage = "Incorrect Old Password"
                this.showErrorMessage = true
              }
              else {
                this.errorMessage = "Something Went Wrong Please try again"
                this.showErrorMessage = true
              }
              this.loading = false
            })
            .catch(e => {

              if (e.response.status === 401) {
                this.errorMessage = "Incorrect Old Password"
                this.showErrorMessage = true
              }
              else {
                this.errorMessage = "Something Went Wrong Please try again"
                this.showErrorMessage = true
              }

              console.log(e)
              this.loading = false
            })

      }
    },
    validations: {
      passwords: {
        password: {
          required
        },
        cpassword: {
          required,
          sameAsPassword: sameAs('password')
        },
      }
    }
  }
</script>
